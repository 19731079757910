import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import FluidImage from '../components/FluidImage'

export default ({ data }) => {
  // const blocks = data.page.blocks

  // const imageIndex = blocks.findIndex(b => b.blockName === "core/image")
  // const imageBlock = blocks.splice(imageIndex, 1)
  // var domparser = new DOMParser();
  // const $ = cheerio.load(imageBlock.innerHTML)
  // const imageSrc = $('img')

  // console.log(imageSrc)

  return <Layout></Layout>
}

// export const query = graphql`
//   query {
//     page: wordpressPage(slug: { eq: "about" }) {
//       blocks {
//         blockName
//         innerHTML
//         attrs {
//           ids
//         }
//         innerContent
//       }
//       slug
//     }
//   }
// `
